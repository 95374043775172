export const DAYJS_CONFIGS: Record<string, Partial<ILocale>> = {
  en: {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'dddd, MMMM D YYYY',
      LLLL: 'dddd, MMMM D, YYYY h:mm A',
      LT: 'h:mm A',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'D MMM, YYYY',
      l: 'D MMM',
      lll: 'D MMM YYYY h:mm A',
    },
    calendar: {
      lastDay: '[Yesterday at] LT',
      sameDay: '[Today at] LT',
      nextDay: '[Tomorrow at] LT',
      lastWeek: '[Last] dddd [at] LT',
      nextWeek: 'dddd LT',
      sameElse: 'L',
    },
  },
  'zh-cn': {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'YYYY年M月D日dddd',
      LLLL: 'YYYY年M月D日ddddAh:mm',
      LT: 'A h:mm',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'YYYY年M月D日',
      l: 'YYYY年M月',
      lll: 'YYYY年M月D日Ah:mm',
    },
    calendar: {
      lastDay: '[昨天] LT',
      sameDay: '[今天] LT',
      nextDay: '[明天] LT',
      lastWeek: '[上]dddd LT',
      nextWeek: 'dddd LT',
      sameElse: 'L',
    },
  },
  'zh-hk': {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'YYYY年M月D日dddd',
      LLLL: 'YYYY年M月D日dddd h:mm A',
      LT: 'h:mm A',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'YYYY年M月D日',
      l: 'YYYY年M月',
      lll: 'YYYY年M月D日 h:mm A',
    },
    calendar: {
      lastDay: '[昨天] LT',
      sameDay: '[今天] LT',
      nextDay: '[明天] LT',
      lastWeek: '[上]dddd LT',
      nextWeek: 'dddd LT',
      sameElse: 'L',
    },
  },
  'pt-br': {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'dddd, D MMMM YYYY',
      LLLL: 'dddd, D MMMM YYYY, HH:mm',
      LT: 'HH:mm',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'D MMMM YYYY',
      l: 'MMMM YYYY',
      lll: 'D MMMM YYYY, HH:mm',
    },
    calendar: {
      lastDay: '[Ontem] LT',
      sameDay: '[Hoje] LT',
      nextDay: '[Amanhã] LT',
      lastWeek: '[último] dddd LT',
      nextWeek: 'dddd LT',
      sameElse: 'L',
    },
  },
  id: {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'dddd, D MMMM YYYY',
      LLLL: 'dddd, D MMMM YYYY, h:mm A',
      LT: 'h:mm A',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'D MMMM YYYY',
      l: 'MMMM YYYY',
      lll: 'D MMMM YYYY, h:mm A',
    },
    calendar: {
      lastDay: '[Kemarin] LT',
      sameDay: '[Hari ini] LT',
      nextDay: '[Besok] LT',
      lastWeek: '[Hari] dddd [lalu] LT',
      nextWeek: '[Hari] dddd LT',
      sameElse: 'L',
    },
  },
  de: {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'dddd, MMMM D YYYY',
      LLLL: 'dddd, MMMM D, YYYY h:mm A',
      LT: 'h:mm A',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'D MMM, YYYY',
      l: 'D MMM',
      lll: 'D MMM YYYY h:mm A',
    },
    calendar: {
      lastDay: '[Gestern] LT',
      sameDay: '[Heute] LT',
      nextDay: '[Morgen] LT',
      lastWeek: '[letzten] dddd LT',
      nextWeek: '[nächsten] dddd LT',
      sameElse: 'L',
    },
  },
  it: {
    formats: {
      L: 'MM/DD/YYYY',
      LL: 'dddd, MMMM D YYYY',
      LLLL: 'dddd, MMMM D, YYYY h:mm A',
      LT: 'h:mm A',
      // @ts-expect-error not sure but docs say have this but types say no
      ll: 'D MMM, YYYY',
      l: 'D MMM',
      lll: 'D MMM YYYY h:mm A',
    },
    calendar: {
      lastDay: '[Ieri] LT',
      sameDay: '[Oggi] LT',
      nextDay: '[Domani] LT',
      lastWeek: '[lo scorso] dddd LT',
      nextWeek: '[il prossimo] dddd LT',
      sameElse: 'L',
    },
  },
};
